<template>
  <v-row>
    <v-col cols="12">
      <v-card>
        <v-card-text>
          <v-simple-table
            v-if="returnedData.length"
            class="return-history-table"
            dense
          >
            <template #default>
              <thead>
                <tr>
                  <th> id</th>
                  <th> Product</th>
                  <th> Ordered Item</th>
                  <th> Returned Item</th>
                  <th> Return Type</th>
                  <th> Reason</th>
                  <th> Status</th>
                  <th> Ordered At</th>
                  <th> Returned At</th>
                  <th> Action</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(item, index) in returnedData"
                  :key="`returned+${index}`"
                >
                  <td>{{ index + 1 }}</td>
                  <td>
                    <!--<img :alt="item.product.name" :src="item.product.image" style="max-width: 100px">-->
                    <p>{{ item.product.name }}</p>
                  </td>
                  <td>{{ item.product.qty }}</td>
                  <td>{{ item.quantity }}</td>
                  <td>{{ item.return_type }}</td>
                  <td>{{ item.description }}</td>
                  <td>
                    <span v-html="statusBadge(item.status)" />
                  </td>
                  <td>{{ item.product.created_at | date }}</td>
                  <td>{{ item.created_at | date }}</td>
                  <td>
                    <v-btn color="primary">
                      History
                    </v-btn>
                  </td>
                </tr>
              <!--          <tr v-for='(order, index) in orders' :key="'orders'+index">-->
              <!--            <td>{{ order.order_number }}</td>-->
              <!--            <td>{{ order.payment_method }}</td>-->
              <!--            <td v-html='statusBadge(order.payment_status)'></td>-->
              <!--            <td v-html='statusBadge(order.status)'></td>-->
              <!--            <td>{{ order.total | currency }}</td>-->
              <!--            <td> <v-btn color='primary' :to="{name: 'Invoice', params: {invoice : order.id }}">View Order</v-btn> </td>-->
              <!--          </tr>-->
              </tbody>
            </template>
          </v-simple-table>
          <v-card-text
            v-else
            class="d-inline-flex justify-space-around"
          >
            <v-alert
              outlined
              type="info"
            >
              You have not returned any order or product
            </v-alert>
          </v-card-text>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { getReturnedOrder } from '@/api/user';

export default {

  name: 'ReturnHistory',
  data: () => ({
    returnedData: [],
  }),
  async mounted() {
    await this.getReturnedOrder();
  },

  methods: {
    async getReturnedOrder() {
      let response = await getReturnedOrder();
      this.returnedData = response.data.data;
    }

  }
};
</script>

<style scoped lang="scss">
.return-history-table {
  //display: table;
  //width: 100%;
  //border-spacing: 0;

  tbody {
    width: 100%;
  }

  thead {
    background-color: #202e82;
    border-spacing: 0;
    border-collapse: collapse;
    width: 100%;
    th {
      color: #ffffff !important;
      text-transform: uppercase;
      font-size: 16px;
    }

    th, td {
      padding: 10px;
      border-spacing: 0;
      white-space: nowrap;
    }
  }

  tr {
    td, th {
      white-space: nowrap;
      vertical-align: middle;
      padding: 10px;
      border-bottom: 1px solid #e3e3e3;
      border-right: 1px solid #e3e3e3;
      border-left: 1px solid #e3e3e3;
    }

    &:nth-child(even) {
      //background-color: #e3e3e3;
    }
  }
}
</style>
